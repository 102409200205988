import React from 'react';
import './AboutSection.css'; // אם ברצונך להשתמש בקובץ CSS נפרד
import image from './image.webp'; // ייבוא התמונה

const AboutSection = () => {
  return (
    <section className="about-container">
      <div className="about-image">
        <img src={image} alt="תמונה עלינו" /> {/* הצגת התמונה */}
      </div>
      <div className="about-text">
        <p>
          אנחנו זוג צעיר, ששאבו את השראתם מהמטבחים העשירים והטעימים של סבא וסבתא. 
          המתכונים שלנו הם תוצר של תרבויות וטעמים שנמסרו לנו מסבא וסבתא טורפליטאים ומרוקאים. 
          אלה הם מתכונים שמגיעים מעמקי לבם, מתכונים עם סיפור, נוסטלגיה וייחודיות שאין לה תחליף.
        </p>
        <p>
          החלטנו לקחת את האוכל הזה, שנכנס לנו ללב ולנשמה, ולבצע את המשימה הגדולה: 
          להביא אותו לכלל הציבור ולהפיץ את הטעמים והחוויות הללו לכמה שיותר אנשים. 
          אנחנו עושים זאת לא מתוך מטרה להרוויח כסף, אלא מתוך רצון אמיתי להעניק, לשתף, ולתת את כל כולנו לאוכל שהכינו הסבתות והסבים שלנו.
        </p>
        <p>
          כל מנה שאנחנו מכינים נושאת עמה את אהבתנו, את המסירות ואת הזיכרונות החמים מהמטבחים של המשפחות שלנו. 
          האוכל שלנו הוא לא רק אוכל – הוא סיפור חיים, הוא געגועים, והוא זיכרון שכולל בתוכו תרבויות, חוויות וניחוחות.
        </p>
        <p>
          במקום העבודה שלנו, אנו מביאים את הטעמים הללו באהבה וביד רחבה, כי אנחנו מאמינים שאין דבר יפה יותר מלשתף את מה שלמדנו וגדלנו עליו עם העולם. 
          כל פריט אוכל שאנחנו מכינים, נעשה מתוך כוונה לגעת בלבבות, להשאיר טעם טוב ולהביא את ההרגשה של בית לכל אחד שיתנסה במנות שלנו.
        </p>
        <p>
          זהו המורשת שלנו – והאוכל שלנו – שנמסר לנו עם הרבה אהבה, ועתה אנחנו מביאים אותו אליכם.
        </p>
      </div>
      <div className="about-button">
        <a href="#more-info" className="btn">עלינו</a>
      </div>
    </section>
  );
};

export default AboutSection;
